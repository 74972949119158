import React from "react";
import { Layout } from "../../components/Layout";
import { Tile } from "../../components/Tile";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";

const Projects = ({ data }) => (
  <Layout>
    <SEO title="Projects" />
    <div className="page-title">
      <span>Projects</span>
    </div>
    <div className="page-content">
      <Tile
        title="Erlang-C Calculator"
        description="Erlang-C calculator written in Javascript using Vue. It uses this formula to predict call center flow based on factors such as agent count, number of calls expected, and average call duration."
        target="/projects/ccplanner"
        tags={["html", "vue", "javascript"]}
        backgroundImageName={data.erlangImageUrl.publicURL}
      />
      <Tile
        title="County Political Leaning"
        description="Python + Javascript project that took FEC individual contribution data from 2017-2020 to determine a county's political leaning and mapped this information for quick dataviz."
        target="/projects/political-leaning/"
        tags={["react", "javascript", "python"]}
        backgroundImageName={data.mapImageUrl.publicURL}
      />
      <Tile
        title="repop"
        description="Google Chrome extension that automatically refreshes all your listings on Depop.com"
        target="/projects/repop/"
        tags={["javascript", "chrome-extension"]}
        backgroundColorName="linear-gradient(5deg, red 30%, black 30%, rgba(0,0,0,0)), linear-gradient(39deg, red 30%, black 30%, rgba(0,0,0,0))"
      />
      <Tile
        title="NFT Generator"
        description="Generates randomized and weighed NFTs based on input images and data. Creates metadate that can be used to bulk import NFTs into marketplaces."
        target="/blog/nft-generator"
        tags={["python", "nfts"]}
        backgroundImageName={data.nftImageUrl.publicURL}
      />
    </div>
  </Layout>
);

export default Projects;

export const query = graphql`
  query getProjectsImagePath {
    erlangImageUrl: file(
      name: { eq: "ccplanner-crop" }
      sourceInstanceName: { eq: "images" }
    ) {
      name
      publicURL
    }
    mapImageUrl: file(
      name: { eq: "map-crop" }
      sourceInstanceName: { eq: "images" }
    ) {
      name
      publicURL
    }
    nftImageUrl: file(
      name: { eq: "nft-crop" }
      sourceInstanceName: { eq: "images" }
    ) {
      name
      publicURL
    }
  }
`;
